<script setup lang="ts">
import { type ComputedRef, type Ref, ref } from 'vue'
import Localization from '../../../constants/localization/localization'
import { useAuthStore } from '~/store/auth/auth'
import { type ILoginCredentials } from '~/repository/modules/auth/types/loginTypes'
import { useLoginStatus } from '~/composables/auth/LoginStatus'
import { Status } from '~/constants/general/Status'
import { Routes } from '~/constants/routes/Routes'

/* interface IAlertMessage {
  title: string
  text: string
  alertType: string
} */

interface IProps {
  closeModal: boolean
}

const { changeLoginStatus } = useLoginStatus()

const { closeModal } = withDefaults(defineProps<IProps>(), {
  closeModal: false,
})

const emitter = defineEmits<{
  (e: 'onEventLogin', loginStatus: Status): void
}>()

// composable
const loginStatus = useLoginStatus()
const form = ref<HTMLFormElement>()
const router = useRouter()

const email: Ref<string> = useState<string>(() => '')
const password: Ref<string> = useState<string>(() => '')

const passwordRules = [(v: string) => !!v || 'Password is required']

const emailRules = [
  (v: string) => !!v || 'Email is required',
  (v: string) => {
    if (/.+@.+\..+/.test(v)) return true

    return 'E-mail must be valid.'
  },
]

const validate = async () => {
  const { valid } = await form.value?.validate()
  if (!valid) return null
  const credentials: ILoginCredentials = {
    email: email.value,
    password: password.value,
  }
  performLogin(credentials)
}
/* const reset = () => {
  form.value?.reset()
} */
/* const resetValidation = () => {
  form.value?.resetValidation()
} */

const status = ref<boolean | null>(null)

const authStore = useAuthStore()

const performLogin = async (credentials: ILoginCredentials) => {
  await authStore.login(credentials)
}

watch(loginStatus.statusLogin, () => {
  if (loginStatus.statusLogin.value === Status.FAILED) {
    emitter('onEventLogin', loginStatus.statusLogin.value)
    status.value = false
  }

  if (loginStatus.statusLogin.value === Status.SUCCESS && authStore.token.length > 0) {
    status.value = true
    setTimeout(() => {
      status.value = false
      email.value = ''
      password.value = ''
      emitter('onEventLogin', loginStatus.statusLogin.value)
      if (!closeModal) {
        router.push({ path: Routes.Dashboard })
      }
    }, 1000)
  }
})

const isSuccessLogin: ComputedRef<boolean> = computed((): boolean => loginStatus.statusLogin.value === Status.SUCCESS)
const isLoading: ComputedRef<boolean> = computed((): boolean => loginStatus.statusLogin.value === Status.LOADING)

onUnmounted(() => {
  changeLoginStatus(Status.INITIAL)
})
</script>

<template>
  <v-card class="p-10 flex-fill" max-width="500" rounded="xl">
    <v-card-title class="mt-5 ml-5 mr-5">{{ $t(Localization.welcome) }}</v-card-title>
    <v-card-subtitle class="ml-5 mr-5">
      <span v-if="status === null">{{ $t(Localization.useYourCredentialsToStartANewDay) }}</span>
      <span v-if="status">{{ $t(Localization.welcome) }}</span>
      <span v-if="status === false" style="color: red !important">{{ $t(Localization.wrongCredentials) }}</span>
    </v-card-subtitle>
    <v-card-text class="ml-5 mr-5">
      <v-form v-if="!isSuccessLogin" ref="form" class="mt-5">
        <v-text-field v-model="email" :counter="255" :rules="emailRules" :label="$t(Localization.email)" :prepend-inner-icon="'mdi-at'" :variant="'outlined'" required></v-text-field>

        <v-text-field v-model="password" :counter="255" :rules="passwordRules" :label="$t(Localization.password)" :prepend-inner-icon="'mdi-lock'" :variant="'outlined'" class="mt-5" required :type="'password'"></v-text-field>
        <div v-if="isLoading" class="d-flex justify-center items-center">
          <v-progress-circular class="pt-3 p-4" indeterminate color="red"></v-progress-circular>
        </div>
        <div v-else v-show="!isSuccessLogin" class="d-flex flex-column">
          <v-btn elevation="0" color="primary" class="text-lowercase mt-5 mb-1" height="50px" @click="validate"
            ><span class="first-text-uppercase">{{ $t(Localization.login) }}</span></v-btn
          >
        </div>
        <div v-if="isSuccessLogin" class="d-flex justify-center items-center"></div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <nuxt-link v-if="!isSuccessLogin" class="mb-5 ml-9" to="forgot-password">{{ $t(Localization.iForgotMyPassword) }}</nuxt-link>
    </v-card-actions>
  </v-card>
</template>
